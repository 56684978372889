/* Mixins */
.message-bell {
  font-size: 22px;
  margin-top: 5px;
  position: relative;
  top: -16px;
  margin-right: 15px;
}
.message-content-item {
  margin-top: 20px;
}
.item-body-content {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.item-body-font {
  font-size: 12px;
}
.message-modal-container .ant-modal-content .ant-modal-close-icon {
  color: #423939 !important;
}
.message-modal-container .ant-modal-confirm-btns {
  width: 100% !important;
  text-align: center !important;
}
.message-modal-container .ant-modal-confirm-content {
  margin-left: 0px !important;
}
.header-alert-overlay {
  background-color: #fff;
  width: 300px;
}
.header-alert-overlay .header-alert-overlay-list {
  width: 300px;
}
.header-alert-overlay .header-alert-overlay-list .alert-item-wrapper {
  width: 300px;
}
.header-alert-overlay .header-alert-overlay-list .alert-item-wrapper .alert-row-wrapper {
  width: 300px;
}
.header-theme-overlay .ant-dropdown-menu {
  background-color: #f0f2f5;
}
.header-theme-overlay .ant-dropdown-menu .ant-dropdown-menu-item:hover, .header-theme-overlay .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover {
  background-color: #f0f2f5;
}
.header-theme-overlay .theme-item {
  display: inline-block;
  height: 25px;
  width: 50px;
}
.header-theme-overlay .theme-item-dark {
  background-color: #001529;
}
.header-theme-overlay .theme-item-light {
  background-color: #fff;
}
.header-theme-overlay .theme-item-blue {
  background-color: #2393cd;
}
.header-theme-overlay .theme-item-red {
  background-color: red;
}
.header-theme-overlay .theme-item-green {
  background-color: green;
}
.header-theme-overlay .theme-item-purple {
  background-color: #a818cc;
}
.header-theme-overlay .theme-item-orange {
  background-color: orange;
}
.dashboard-component .dashboard-header {
  z-index: 1;
  width: 100%;
  height: 48px;
  line-height: 48px;
  padding: 0;
}
.dashboard-component .dashboard-header .ant-col {
  height: 48px;
}
.dashboard-component .dashboard-header .header-logo {
  margin-left: 24px;
  display: inline-block;
}
.dashboard-component .dashboard-header .header-logo .header-title {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
}
.dashboard-component .dashboard-header .header-logo .header-current-project {
  font-size: 14px;
  color: #aaa;
}
.dashboard-component .dashboard-header .header-current-version {
  margin-right: 16px;
  vertical-align: top;
  color: #aaa;
}
.dashboard-component .dashboard-header .header-theme {
  margin-left: 20px;
}
.dashboard-component .dashboard-header .username-menu {
  height: 48px;
  padding-right: 20px;
}
.dashboard-component .dashboard-header .username-menu .drop-menu-text {
  display: inline-block;
  max-width: 200px;
  font-weight: 400;
  color: #fff;
}
.dashboard-component .dashboard-header .username-menu .user-header {
  font-size: 30px;
  vertical-align: top;
  margin-top: 9px;
  margin-right: 4px;
}
.dashboard-component .dashboard-header .username-menu .header-project-wrapper {
  display: inline-block;
  margin: 0 20px;
  vertical-align: top;
  height: 100%;
}
.dashboard-component .dashboard-header .username-menu .backto-corp-a {
  vertical-align: top;
  margin-left: 20px;
  color: #fff;
}
.dashboard-component .dashboard-header .username-menu .right-inline-block {
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;
  height: 100%;
}
.dashboard-component .ant-layout-content {
  overflow: auto;
}
.dashboard-component .collapsed-wrapper {
  cursor: pointer;
  padding: 12px 0;
  font-size: 18px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.dashboard-component .dashboard-sider {
  min-height: calc(100vh - 48px) !important;
  height: calc(100vh - 48px);
}
.dashboard-component .dashboard-sider .ant-menu.ant-menu-root {
  height: calc(100vh - 48px - 52px);
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dashboard-component .dashboard-sider .ant-menu.ant-menu-root .anticon {
  margin-left: -2px;
  margin-top: -4px;
  font-size: 18px;
  vertical-align: middle;
}
.dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-divider {
  margin: 0;
}
.dashboard-component .dashboard-view {
  padding: 0;
  height: calc(100vh - 48px);
  overflow: auto;
  overflow-x: auto !important;
}
.dashboard-component .dashboard-view .dashboard-content {
  min-width: 1024px;
}
.dashboard-theme-light .dashboard-header {
  color: #fff;
  background: #28549e;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.07);
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.07);
}
.dashboard-theme-light .dashboard-header .header-logo .header-current-project {
  color: #aaa;
}
.dashboard-theme-light .dashboard-sider {
  background: #F7F8FA;
}
.dashboard-theme-light .collapsed-wrapper {
  border-top: solid 1px #e8e8e8;
  background-color: #fff;
}
.dashboard-theme-light .collapsed-wrapper:hover {
  color: var(--primaryColor);
}
.dashboard-theme-dark .dashboard-header {
  color: #fff;
  background: #001529;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(43, 53, 62, 0.6);
          box-shadow: 0px 0px 8px 0px rgba(43, 53, 62, 0.6);
}
.dashboard-theme-dark .dashboard-header .header-logo .header-current-project {
  color: #aaa;
}
.dashboard-theme-dark .dashboard-sider {
  background: #252B36;
}
.dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root {
  color: #96A1B3;
  background: #252B36;
}
.dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item {
  color: rgba(255, 255, 255, 0.65);
}
.dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item.ant-menu-item-active, .dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item:active, .dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-submenu-title:active {
  color: #fff;
  background-color: transparent;
}
.dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-divider {
  background-color: #1F252E;
}
.dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-sub {
  background: #191F28;
}
.dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-selected, .dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-sub-item-selected {
  background-color: transparent;
}
.dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-selected span, .dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-selected i, .dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-sub-item-selected span, .dashboard-theme-dark .dashboard-sider .ant-menu.ant-menu-root .ant-menu-sub-item-selected i {
  color: var(--primaryColor);
}
.dashboard-theme-dark .collapsed-wrapper {
  border-top: solid 1px #1F252E;
}
.dashboard-theme-dark .collapsed-wrapper:hover {
  color: var(--primaryColor);
}
.dashboard-theme-dark .dashboard-view {
  background: #f0f2f5;
}